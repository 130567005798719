import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const Media = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleRedirect = () => {
    navigate('/'); // Redirect to home page
  };

  return (
    <div 
      style={{
        width: '100%',
        height: '100vh', // Full viewport height
        color: 'white',
        textAlign: 'center',
        backgroundColor: '#000312',
        display: 'flex',
        flexDirection: 'column', // Stack items vertically
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0',
        padding: '0'
      }}
    >
      <img 
        src="/logo1.svg" 
        alt="Secure Domains Logo" 
        style={{ marginBottom: '20px',height:'100px' }} // Adjust size as needed
      />
      <h1>Coming Soon</h1>
      <button 
        onClick={handleRedirect}
        style={{
          marginTop: '20px',
          padding: '10px 20px',
          backgroundColor: '#16757D',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
        Go to Home
      </button>
    </div>
  );
}

export default Media;
