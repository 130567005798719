import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from "./Home";
import Contact from "./ContactUs";
import AboutUsPage from "./AboutUs";
import Product from "./Product";
import Media from "./Media";

const DynamicTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const pageTitles = {
      "/": "Secure Domains - Home",
      "/about": "Secure Domains - About Us",
      "/contact": "Secure Domains - Contact Us",
      "/product": "Secure Domains - Products",
      "/media": "Secure Domains - Media",
    };

    const defaultTitle = "Secure Domains";

    document.title = pageTitles[location.pathname] || defaultTitle;
  }, [location]);

  return null; // This component doesn't render anything
};

const App = () => {
  return (
    <Router>
      <DynamicTitle />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product" element={<Product />} />
        <Route path="/media" element={<Media />} />
      </Routes>
    </Router>
  );
};

export default App;
